
  <template>
  <v-form class="px-8 py-4" ref="sterilizeForm" @submit.prevent="submitForm">
    <v-text-field label="Issue" v-model="issue" disabled> </v-text-field>
    <v-text-field
      label="* Your Name"
      v-model="yourName"
      :rules="nameRules"
      :counter="10"
      required
      outlined
    ></v-text-field>
    <v-text-field
      label="* Primary Phone Number"
      v-model="phoneNumber1"
      :rules="phoneRules"
      :counter="10"
      required
    ></v-text-field>
    <v-text-field
      label="Alternate Phone Number"
      v-model="phoneNumber2"
    ></v-text-field>
    <v-text-field
      label="* Location of the dog"
      v-model="location"
      :rules="locationRules"
      required
    ></v-text-field>
    <v-text-field label="Landmark" v-model="landmark"></v-text-field>

    <v-radio-group v-model="isDogFriendly">
      <div class="pl-2">* Is dog Friendly ?</div>
      <v-radio label="Yes" key="d1" value="1"></v-radio>
      <v-radio label="No" key="d2" value="0"></v-radio>
    </v-radio-group>
    <v-radio-group v-model="gender">
      <div class="pl-2">Dog Gender</div>
      <v-radio label="Male" key="g1" value="1"></v-radio>
      <v-radio label="Female" key="g2" value="0"></v-radio>
    </v-radio-group>
    <v-textarea
      name="input-7-4"
      label="Comments"
      v-model="comments"
    ></v-textarea>
    <v-file-input
      accept="image/png, image/jpeg, image/bmp, image/gif"
      prepend-icon="mdi-camera"
      label="Dog Photo"
      placeholder="Upload Dog Photo"
      v-model="imageFileModel"
      @change="imageChanged"
    ></v-file-input>

    <div class="px-2 pb-2 mb-8">
      <v-btn class="mr-2" @click="submitForm"> Submit </v-btn>
      <v-btn class="mr-2" @click="clearForm">Clear</v-btn>
      <v-progress-circular
        class="mr-2"
        :size="40"
        :width="5"
        color="green"
        indeterminate
        :hidden="isLoadingHidden"
      ></v-progress-circular>
    </div>
  </v-form>
</template>
  
<script>
import axios from "axios";
export default {
  data() {
    return {
      valid: false,
      issue: "Dog Sterilization Request",
      yourName: "",
      phoneNumber1: "",
      phoneNumber2: "",
      comments: "",
      location: "",
      landmark: "",
      isDogFriendly: "1",
      gender: "0",
      imageData: null,
      imageFileModel: [],
      isLoadingHidden: true,

      nameRules: [
        (v) => !!v || "Name is required",
        (v) =>
          (v && v.length > 3) || "Name must be at least 3 characters long.",
      ],
      locationRules: [
        (v) => !!v || "Location is required",
        (v) =>
          (v && v.length >= 3) ||
          "Location must be at least 3 characters long.",
      ],
      phoneRules: [
        (v) => !!v || "Phone Number is required",
        (v) =>
          (v && v.length >= 10) ||
          "Phone Number must not be less than 10 digits.",
      ],
    };
  },
  watch: {
    isDogFriendly(newValue, oldValue) {
      console.log(`isDogFriendly ${newValue}`);
    },
  },
  methods: {
    resetAllData() {
      this.valid = false;
      this.issue = "Dog Sterilization Request";
      this.yourName = null;
      this.phoneNumber1 = null;
      this.phoneNumber2 = "";
      this.comments = "";
      this.location = null;
      this.landmark = "";
      this.isDogFriendly = "1";
      this.gender = "0";
      this.imageFileModel = [];
      this.isLoadingHidden = true;
    },
    imageChanged(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.imageFileModel = [];
      this.imageFileModel.push(files[0]);
    },

    submitForm() {
      this.validate();
      if (this.valid) {
        //Submit
        const formData = new FormData();
        formData.append("issue", this.issue);
        formData.append("yourName", this.yourName);
        formData.append("phoneNumber1", this.phoneNumber1);
        formData.append("phoneNumber2", this.phoneNumber2);
        formData.append("location", this.location);
        formData.append("landmark", this.landmark);
        formData.append("isDogFriendly", this.isDogFriendly);
        formData.append("gender", this.gender);
        formData.append("comments", this.comments);
        if (this.imageFileModel.length > 0) {
          formData.append("imageFile", this.imageFileModel[0]);
        }
        this.isLoadingHidden = false;
        this.uploadFormData(formData);
      } else {
        alert("Please fill the required fields.");
      }
    },
    async uploadFormData(formData) {
      try {
        const response = await axios.post(
          "https://www.codeokplease.com/act-sterilize-server/sterilizations/",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        if (response.status == 200) {
          this.resetAllData();
          alert("Successfuly uploaded your request.");
        } else {
          this.isLoadingHidden = true;
          alert("Failed to upload details. Please try again.");
        }
      } catch (error) {
        this.isLoadingHidden = true;
        console.log(error);
      }
    },

    validate() {
      this.$refs.sterilizeForm.validate();
      if (this.yourName && this.phoneNumber1 && this.location) {
        this.valid = true;
      }
    },

    clearForm() {
      this.resetAllData();
    },
  },
};
</script>
