<template>
  <nav>
    <v-toolbar flat color="green" max-height="44">
      <v-toolbar-title>
        <span>ACT</span>
        <span class="font-weight-light">Sterilize</span>
      </v-toolbar-title>
    </v-toolbar>
  </nav>
</template>

<script>
export default {
  setup() {},
};
</script>