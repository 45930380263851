import { createRouter, createWebHistory } from "vue-router";
import SterilizePageView from "./views/SterilizePageView.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", redirect: "/sterilize" },
    { path: "/sterilize", component: SterilizePageView }
  ],
});

export default router;
