import { createApp } from "vue";
import router from './router.js'
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";


loadFonts();

createApp(App).
    use(vuetify).
    use(router).
    mount("#app");
