<template>
  <v-app>
    <navbar-view />
    <router-view></router-view>
  </v-app>
</template>

<script>
import NavbarView from "./components/NavbarView.vue";
export default {
  name: "App",
  components: { NavbarView },
  data: () => ({}),
};
</script>
